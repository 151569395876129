<template>
  <Heading v-if="!hideTitle" size="md" class="text-center mb-sm md:mb-lg mt-3xl">Other branches nearby</Heading>
  <div
    v-if="nearestBranches"
    :class="[!branchSearch ? '0px' : 'py-3xl']"
    data-fs="branchDetailsNearbyBranches"
    data-test-id="branchDetailsNearbyBranches"
  >
    <LazySlider
      :space-between="16"
      :center-insufficient-slides="false"
      :draggable="false"
      :autoplay="false"
      :breakpoints="breakpoints"
      class="wrapper-left !py-none"
    >
      <swiper-slide v-for="branch in nearestBranches" :key="`${branch.id}-${lastFetchTimestamp}`">
        <BranchCard
          :branch="branch"
          :link-component="NuxtLink"
          path="/branches/"
          :selected-branch="selectedBranch"
          :is-new-branch="isNewBranch(branch)"
          :branch-opening-date="getNewBranchOpeningDate(branch)"
          :opens-in-the-future="opensInTheFuture(branch)"
          @update:branch="(value) => selectBranch(value)"
        />
      </swiper-slide>
    </LazySlider>
  </div>
</template>

<script setup lang="ts">
import type { BranchNearestProps } from "./branchDetailsTypes";
import { SwiperSlide } from "swiper/vue";
const lastFetchTimestamp = ref(Date.now());

const NuxtLink = resolveComponent("NuxtLink");

const { fetchNearestBranch, selectedBranch, selectBranch, isNewBranch, getNewBranchOpeningDate, opensInTheFuture } =
  useBranches();

const props = defineProps<BranchNearestProps>();

const nearestBranches = ref();

const breakpoints = {
  320: {
    slidesPerView: 1,
    centerMode: true,
  },
  360: {
    slidesPerView: 1.185,
    centerMode: true,
  },
  370: {
    slidesPerView: 1.19,
    centerMode: true,
  },
  380: {
    slidesPerView: 1.21,
    centerMode: true,
  },
  390: {
    slidesPerView: 1.25,
    centerMode: true,
  },
  400: {
    slidesPerView: 1.28,
    centerMode: true,
  },
  420: {
    slidesPerView: 1.34,
    centerMode: true,
  },
  430: {
    slidesPerView: 1.345,
    centerMode: true,
  },
  440: {
    slidesPerView: 1.4,
    centerMode: true,
  },
  450: {
    slidesPerView: 1.44,
    centerMode: true,
  },
  460: {
    slidesPerView: 1.47,
    centerMode: true,
  },
  470: {
    slidesPerView: 1.51,
    centerMode: true,
  },
  480: {
    slidesPerView: 1.53,
    centerMode: true,
  },
  490: {
    slidesPerView: 1.56,
    centerMode: true,
  },
  500: {
    slidesPerView: 1.57,
    centerMode: true,
  },
  510: {
    slidesPerView: 1.6,
    centerMode: true,
  },
  520: {
    slidesPerView: 1.62,
    centerMode: true,
  },
  530: {
    slidesPerView: 1.68,
    centerMode: true,
  },
  540: {
    slidesPerView: 1.7,
    centerMode: true,
  },
  550: {
    slidesPerView: 1.72,
    centerMode: true,
  },
  560: {
    slidesPerView: 1.8,
    centerMode: true,
  },
  570: {
    slidesPerView: 1.82,
    centerMode: true,
  },
  580: {
    slidesPerView: 1.84,
    centerMode: true,
  },
  590: {
    slidesPerView: 1.87,
    centerMode: true,
  },
  600: {
    slidesPerView: 1.91,
  },
  610: {
    slidesPerView: 1.95,
  },
  620: {
    slidesPerView: 2,
  },
  650: {
    slidesPerView: 2.05,
  },
  660: {
    slidesPerView: 2.1,
  },
  680: {
    slidesPerView: 2.15,
  },
  700: {
    slidesPerView: 2.2,
  },
  720: {
    slidesPerView: 2.3,
  },
  750: {
    slidesPerView: 2.4,
  },
  790: {
    slidesPerView: 2.5,
  },
  800: {
    slidesPerView: 2.5,
  },
  810: {
    slidesPerView: 2.6,
  },
  850: {
    slidesPerView: 2.65,
  },
  900: {
    slidesPerView: 2.8,
  },
  910: {
    slidesPerView: 2.9,
  },
  920: {
    slidesPerView: 2.95,
  },
  950: {
    slidesPerView: 3,
  },
  990: {
    slidesPerView: 3.1,
  },
  1000: {
    slidesPerView: 3.15,
  },
  1050: {
    slidesPerView: 3.23,
  },
  1100: {
    slidesPerView: 3.4,
  },
  1150: {
    slidesPerView: 3.6,
  },
  1200: {
    slidesPerView: 3.8,
  },
  1250: {
    slidesPerView: 3.9,
  },
  1300: {
    slidesPerView: 4.1,
  },
  1350: {
    slidesPerView: 4.2,
  },
  1400: {
    slidesPerView: 4.4,
  },
  1600: {
    slidesPerView: 4.5,
  },
  1650: {
    slidesPerView: 4.6,
  },
  1700: {
    slidesPerView: 4.9,
  },
  1750: {
    slidesPerView: 5,
  },
};

onMounted(async () => {
  if (props.lng && props.lat) {
    nearestBranches.value = await fetchNearestBranch({
      lat: props.lat,
      lng: props.lng,
      limit: 10,
      excludeBranchId: props.currentBranchId,
      includeComingSoon: true,
    });

    lastFetchTimestamp.value = Date.now();
  }
});

watchEffect(async () => {
  if (props.lng && props.lat) {
    nearestBranches.value = await fetchNearestBranch({
      lat: props.lat,
      lng: props.lng,
      limit: 10,
      includeComingSoon: true,
    });

    lastFetchTimestamp.value = Date.now();
  }
});
</script>
